var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 140px;"
      },
      model: _vm.item,
      form: _vm.form
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "Cost to each upload"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["amount", {
        rules: [{
          required: true,
          message: "必填!"
        }],
        validateFirst: true,
        initialValue: _vm.item.amount
      }],
      expression: "[\n              'amount',\n              {\n                rules: [\n                  { required: true, message: '必填!' },\n                ],\n                validateFirst: true,\n                initialValue: item.amount,\n              },\n            ]"
    }],
    attrs: {
      type: "number",
      autoComplete: "off",
      placeholder: "",
      disabled: _vm.isDisabled,
      min: 0.01
    }
  })], 1)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["decentralized:btn:config"],
      expression: "['decentralized:btn:config']"
    }],
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("提交")])], 1)])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };