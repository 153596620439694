import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import DecentralizedApi from "@/api/decentralized";
import Mixin from "@/utils/mixin";
export default {
  name: "decentrealizedForm",
  components: {},
  props: {},
  mixins: [Mixin],
  data: function data() {
    return {
      form: this.$form.createForm(this),
      spinning: false,
      loading: false,
      item: {},
      action: ""
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return false;
    }
  },
  created: function created() {
    this.find();
  },
  methods: {
    find: function find() {
      var _this = this;
      this.spinning = true;
      DecentralizedApi.getConfig({}).then(function (res) {
        _this.item = res;
      }).catch(function (err) {
        _this.toast(err || "接口报错", "error");
      }).finally(function () {
        _this.spinning = false;
      });
    },
    cancle: function cancle() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this2 = this;
      if (this.loading) return;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this2.loading = true;
          var params = {};
          params = _objectSpread({}, values);
          DecentralizedApi.setting(params).then(function () {
            _this2.toast("操作成功", "success");
            _this2.$emit("submit");
          }).catch(function (err) {
            _this2.toast((err === null || err === void 0 ? void 0 : err.msg) || "操作失败", "error");
          }).finally(function () {
            setTimeout(function () {
              _this2.loading = false;
            }, 1000);
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    }
  }
};