import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import Mixin from "@/utils/mixin";
import TableMixin from "@/utils/tableMixin";
import { searchKeys, columns } from "./config/index";
import DecentralizedApi from "@/api/decentralized";
import Form from "./components/form.vue";
export default {
  name: "decentralized",
  components: {
    Form: Form
  },
  data: function data() {
    return {
      createForm: this.$form.createForm(this),
      loading: false,
      columns: columns,
      searchKeys: searchKeys,
      modalVisible: false,
      defaultIndex: 0,
      types: [{
        id: undefined,
        name: "全部",
        hide: false
      }, {
        id: 0,
        name: "未上传",
        hide: true
      }, {
        id: 1,
        name: "上传中",
        hide: false
      }, {
        id: 2,
        name: "已上传",
        hide: false
      }, {
        id: 3,
        name: "上传失败",
        hide: true
      }]
    };
  },
  mixins: [Mixin, TableMixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {};
    },
    filteredTypes: function filteredTypes() {
      return this.types.filter(function (it) {
        return !it.hide;
      });
    },
    status: function status() {
      return this.filteredTypes[this.defaultIndex].id;
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {},
  deactivated: function deactivated() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  beforeDestroy: function beforeDestroy() {
    window.GLOBAL.vbus.$emit("pageBtnDisabled", []);
  },
  methods: {
    statusText: function statusText(status) {
      var it = this.types.find(function (item) {
        return item.id === status;
      });
      return (it === null || it === void 0 ? void 0 : it.name) || "";
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    // 取消启用/禁用
    handleDelCancle: function handleDelCancle() {
      this.isConfirmVisible = false;
      this.subLoading = false;
    },
    getList: function getList() {
      var _this = this;
      this.createForm.validateFields(function (err, values) {
        if (!err) {
          _this.selectedRowKeys = [];
          _this.loading = true;
          var params = _objectSpread({
            page: _this.page,
            size: _this.size,
            uploaddecentralizedresult: _this.status
          }, values);
          DecentralizedApi.list(_objectSpread({}, params)).then(function (res) {
            _this.loading = false;
            _this.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
            _this.total = (res === null || res === void 0 ? void 0 : res.total) || 0;
          }).catch(function () {
            _this.dataSource = [];
            _this.total = 0;
          }).finally(function () {
            // setTimeout(() => {
            _this.loading = false;
            // }, 3000);
          });
        } else {
          console.log(err);
        }
      });
    },
    handleSearch: function handleSearch() {
      this.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.page = 1;
      this.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    handleAction: function handleAction(action, record) {},
    tabClick: function tabClick(e, index) {
      this.createForm.resetFields(["status"]);
      this.defaultIndex = index;
      this.page = 1;
      this.size = 20;
      this.handleSearch();
    },
    /*******表单相关start********/handleFormCancel: function handleFormCancel() {
      // console.log("取消");
      this.modalVisible = false;
    },
    handleFormSubmit: function handleFormSubmit() {
      this.modalVisible = false;
      this.getList();
    },
    /*******表单相关end********/
    /*******表格复选框start********/
    pageBtnClicked: function pageBtnClicked(module, action) {
      // console.log(action, ADD)
      if (action == ADD) {
        this.handleAction(action, {});
        return;
      }
    } /*******表格复选框end********/
  }
};