var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置 ")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("DTab", {
    attrs: {
      defaultIndex: _vm.defaultIndex,
      titles: _vm.filteredTypes,
      handleClick: _vm.tabClick
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("div", {
          staticStyle: {
            flex: "1",
            "text-align": "right"
          }
        }, [_c("a-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              _vm.modalVisible = true;
            }
          }
        }, [_vm._v("配置")])], 1)];
      },
      proxy: true
    }])
  }), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref, index) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return _c("div", {
            key: index
          }, [key == "uploaddecentralizedresult" ? [_vm._v(" " + _vm._s(_vm.statusText(text)) + " ")] : key == "videotitle" ? [text ? _c("a-tooltip", {
            staticClass: "ellipsis-5",
            attrs: {
              placement: "topLeft"
            }
          }, [_vm._v(" " + _vm._s(text) + " "), _c("div", {
            staticStyle: {
              "max-height": "50vh",
              overflow: "auto"
            },
            attrs: {
              slot: "title"
            },
            slot: "title"
          }, [_vm._v(" " + _vm._s(text) + " ")])]) : [_vm._v("---")]] : key == "metadataurl" ? [!text ? _c("span", [_vm._v("---")]) : _vm._e(), _c("a", {
            attrs: {
              href: text,
              target: "_blank"
            }
          }, [_vm._v(_vm._s(text))])] : key == "createtimedate" ? [_vm._v(" " + _vm._s(text ? _vm.$moment(text * 1000).format("YYYY-MM-DD HH:mm:ss") : "---") + " ")] : key == "donedate" ? [_vm._v(" " + _vm._s(text ? _vm.$moment(text * 1000).format("YYYY-MM-DD HH:mm:ss") : "---") + " ")] : [_vm._v(_vm._s(record[key] || "---"))]], 2);
        }
      };
    })], null, true)
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  }), _c("DPopup", {
    attrs: {
      title: "配置",
      comVisible: _vm.modalVisible,
      isClosable: true,
      modalWidth: "500px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.modalVisible = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("Form", {
          on: {
            cancel: _vm.handleFormCancel,
            submit: _vm.handleFormSubmit
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };