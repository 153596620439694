// 去中心化存储相关
import { POST, GET } from '@/utils/util';
var DecentralizedApi = {
  // https://gitlab.test.solestagesupply.com/docs/api-docs/-/blob/release/DC%E7%A4%BE%E5%8C%BA%E5%92%8C%E6%96%B0%E9%97%BB/%E7%A4%BE%E5%8C%BAAPP%E6%8E%A5%E5%8F%A3/APP%E7%AB%AF/%E3%80%90%E5%8E%BB%E4%B8%AD%E5%BF%83%E5%8C%96%E5%AD%98%E5%82%A8-web%E3%80%91%E5%8E%BB%E4%B8%AD%E5%BF%83%E5%8C%96%E5%AD%98%E5%82%A8%E5%88%97%E8%A1%A8.md

  // 获取配置
  getConfig: function getConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/open/videos/decentralized', params);
  },
  // 配置费用
  setting: function setting() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videos/decentralized/cost/setting', params);
  },
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/videos/decentralized/list', params);
  }
};
export default DecentralizedApi;